import { BaseDto } from '@/shared/dtos/base-dto';

export class anotacion extends BaseDto {
    public id_paciente !: number;
    public id_nutricionista !: number;
    public fecha!:Date;
    public id_entrevista !: number;
    public anotaciones_html !: string;

 } 

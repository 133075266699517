import { store } from '@/store/store';
import { anotacion } from '@/shared/dtos/anotacion';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'anotacionModule',
    store,
    dynamic: true
})
class anotacionModule extends VuexModule {
    public anotaciones: anotacion[] = [];
    public anotacion: anotacion = new anotacion();

    @Action({ commit: 'onGetanotaciones' })
    public async getanotaciones() {
        return await ssmHttpService.get(API.anotaciones);
    }

    @Action({ commit: 'onGetanotaciones' })
    public async getanotaciones_pacientes(id: number) {
        return await ssmHttpService.get(API.anotaciones + '/anotaciones/' + id);
    }

    @Action({ commit: 'onGetanotacion' })
    public async getanotacion(id: any) {
        return await ssmHttpService.get(API.anotaciones + '/' + id);
    }

    @Action
    public async guardaranotacion(anotacion: anotacion) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.post(API.anotaciones, anotacion.toJson());
    }

    @Action
    public async modificaranotacion(anotacion: anotacion) {
        await ssmHttpService.put(API.anotaciones + '/' + anotacion.id, anotacion);
    }

    @Action
    public async eliminaranotacion(anotacion: anotacion) {
        await ssmHttpService.delete(API.anotaciones + '/' + anotacion.id, null, false);
    }

    @Mutation
    public onGetanotaciones(res: anotacion[]) {
        this.anotaciones = res ? res.map((x) => new anotacion(x)) : [];
    }

    @Mutation
    public onGetanotacion(res: anotacion) {
        this.anotacion = new anotacion(res);
    }

}
export default getModule(anotacionModule);
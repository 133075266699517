










































import { Component, Vue, Ref, Prop, PropSync } from "vue-property-decorator";
import { RouterNames } from "@/router/routernames";
import anotacionModule from "@/store/modules/anotacion-module";
import { ClassColumnDataTable } from "@/components/DatatableGenerico/ClassColumnDataTable";
import { datatypes } from "@/components/DatatableGenerico/datatypes";
import { anotacion } from "@/shared/dtos/anotacion";
import { UtilsString } from "@/utils/utils-string";
import { UtilsNotify } from "@/utils/utils-notify";
import { UtilsDate } from "@/utils/utils-date";
@Component({
  components: {
    DataTable: () => import("@/components/DatatableGenerico/DataTable.vue"),
    HTMLEditor: () => import("@/components/html-editor/html-editor.vue")
  }
})
export default class pacienteAnotaciones extends Vue {
  @Ref("DataTable") DataTable!: any;
  @Prop({ type: Number }) id_paciente!: number;
  public synceddialog: boolean = false;
  public anotacion_dicion!: anotacion;
  public editor: string = "";
  public fecha: string = "";
  created() {
    if (this.id_paciente > 0) {
      anotacionModule.getanotaciones_pacientes(
        this.id_paciente ? this.id_paciente : 0
      );
      //this.anotacion_dicion.fecha;
    }
  }
  public get titulo() {
    if (!UtilsString.IsNullOrWhiteSpace(this.fecha.toString())) {
      return new Date().toString();
    }
    return "Anotación";
  }
  public get anotaciones() {
    return anotacionModule.anotaciones;
  }

  public get Columns() {
    var Columnas: ClassColumnDataTable[] = [];
    Columnas.push(
      new ClassColumnDataTable(
        "fecha",
        "Fecha anotacion",
        datatypes.date,
        true,
        datatypes.maxlength
      )
    );
    return Columnas;
  }
  public actualizaranotacion() {

    this.anotacion_dicion.anotaciones_html = this.editor;
    anotacionModule.modificaranotacion(this.anotacion_dicion).then(() => {
      this.synceddialog = false;
    });
    UtilsNotify.NotificacionSuccess(
      "Anotación",
      "Se ha actualizado correctamente"
    );
  }
  public editarAnotacion(id_anotacion: number) {
    this.anotacion_dicion = this.anotaciones.find((x)=> x.id===this.DataTable.RowIndex())!;
    this.editor = UtilsString.ValueOfValueName(
      this.anotacion_dicion,
      "anotaciones_html"
    );
    this.synceddialog = true;
  }
}
